:root {
    --hover: #19415a;
}

html, body {
    overflow: hidden;
}

body {
    position: relative;
}

#root {
    background-color: black;
}

.App {
    min-height: 100vh;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}

#background {
    width: 1325px;
    height: 790px;
}

#container {
    width: 1280px;
    height: 720px;
    display: flex;
    margin-left: 24px;
    margin-top: 57px;
    letter-spacing: 1px;
}

#about-body, #help-list {
    letter-spacing: 0px;
}

#main {
    width: 980px;
    position: relative;
    display: flex;
    flex-direction: column;
}

#main-content {
    height: calc(100% - 70px);
}

#side {
    width: 300px;
    text-align: left;
    position: relative;
}

#quest-log {
    width: 100%;
    margin-top: 0px;
    height: calc(100% - 70px);
}

#character {
    padding: 10px;
    text-align: left;
    display: flex;
    position: relative;
}

#character-name {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 650px;
}

#character-info {
    font-size: 16px;
    line-height: 16px;
}

#character-levels {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#xp-bar {
    width: 300px;
    height: 14px;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    box-shadow: inset 0 0 6px #14638d;
}

#xp-bar.blue .xp-bar-inner {
    background: linear-gradient(rgb(55,132,214) 0%, rgba(254,254,254) 30%, rgb(254,254,254) 47%, rgb(254,254,254) 71%, rgb(55,132,214) 100%);
    box-shadow: 0 0 10px 3px #3784d6;
}

#xp-bar.green .xp-bar-inner {
    background: linear-gradient(rgb(135,220,90) 0%, rgba(254,254,254) 30%, rgb(254,254,254) 47%, rgb(254,254,254) 71%, rgb(135,220,90) 100%);
    box-shadow: 0 0 10px 3px #7EC855;
}

#xp-bar.purple .xp-bar-inner {
    background: linear-gradient(rgb(202,116,221) 0%, rgba(254,254,254) 30%, rgb(254,254,254) 47%, rgb(254,254,254) 71%, rgb(202,116,221) 100%);
    box-shadow: 0 0 10px 3px #ca74dd;
}

#xp-bar.red .xp-bar-inner {
    background: linear-gradient(rgb(229,17,21) 0%, rgba(254,254,254) 30%, rgb(254,254,254) 47%, rgb(254,254,254) 71%, rgb(229,17,21) 100%);
    box-shadow: 0 0 10px 3px #e51115;
}

.xp-bar-inner {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 12px;
    pointer-events: none;
    transition: all 1s;
    border-radius: 4px 0px 0px 4px;
    filter: blur(1px);
}

.xp-bar-text {
    position: absolute;
    left: 10px;
    top: 0px;
    line-height: 13px;
    font-size: 14px;
    font-weight: bold;
    mix-blend-mode: difference;
    user-select: none;
}

.alignment-bar {
    width: 100%;
    height: 12px;
    background: linear-gradient(270deg, #45caff 0%, #ff1b6b 100%);
    border-radius: 4px;
    position: relative;
}

.alignment-bar:before {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    background: linear-gradient(270deg, #45caff 0%, #000000 50%, #ff1b6b 100%);
    filter: blur(8px);
}

.alignment-bar-indicator {
    position: absolute;
    height: 23px;
    width: 8px;
    top: -6px;
    background: #4fb1d6;
    border: 2px solid #50deff;
    border-radius: 3px;
    mix-blend-mode: luminosity;
    transform: translateX(-50%);
    transition: 1s all;
}

.side-group {
    position: relative;
    height: calc(100% / 5 * 2);
    padding-bottom: 5px;
}

.side-group + .side-group {
    height: calc(100% / 5 * 3);
}

.side-group-title {
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    line-height: 30px;
    box-shadow: inset 0 0 16px #14638d;
    margin-bottom: 5px;
}

.side-group-title.active {
    border-radius: 0px 5px 0px 0px;
}

.side-group-list {
    height: calc(100% - 35px);
    padding-left: 12px;
    overflow: hidden;
    overflow-y: auto;
    width: calc(100% - 5px);
}

.side-group.companion .side-group-list {
    max-height: 160px;
}

.quest-group + .quest-group {
    margin-top: 10px;
}

.quest-category {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;
}

.quest-category-name {
    vertical-align: text-top;
}

.quest-category-count {
    font-size: 11px;
    padding-left: 5px;
    line-height: 0px;
}

.quest-category-group {
    height: auto;
    overflow: hidden;
}

.quest-category-group.collapsed {
    height: 0px;
}

.quest {
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    padding-left: 10px;
    margin-right: 10px;
    user-select: none;
}

.quest:hover {
    background: var(--hover);
}

.quest.main {
    color: plum;
}

.quest.bonus {
    padding-left: 20px;
}

.quest.active {
    cursor: unset;
}

#blurb, #preferences {
    width: 600px;
    margin: auto;
}

#blurb-header, #preferences-header, #quests-header {
    margin: 20px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;
    text-transform: uppercase;
}

#preferences-header {
    margin-bottom: 20px;
}

.blurb-paragraph {
    text-align: justify;
    margin-bottom: 20px;
}

#quest-summary {
    width: 600px;
    margin: auto;
}

#quests #quest-summary {
    width: 565px;
}

#quests, #codices {
    margin-top: 10px;
}

#quest-summary-title {
    margin: 20px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

#quest-summary-name, #quest-history-task-heading {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

#quest-history-task-heading {
    margin-top: 20px;
    text-align: center;
}

.quest-summary-label {
    width: 150px;
    text-align: left;
    display: inline-block;
    line-height: 24px;
    vertical-align: top;
}

.quest-summary-value {
    width: 200px;
    text-align: right;
    display: inline-block;
    line-height: 24px;
}

#quest-summary-accept {
    margin-top: 20px;
}

#quest {
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#quest-heading {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: left;
    height: 24px;
}

#quest-text {
    display: flex;
    margin-bottom: 20px;
}

#quest-info {
    display: flex;
    position: relative;
    height: 280px;
}

#quest-info.pending {
    height: auto;
}

#quest-info-left {
    margin-right: 20px;
    padding: 10px;
    width: 600px;
}

#quest-info-right {
    padding: 10px;
    width: 360px;
}

#quest-xp {
    font-weight: bold;
    margin-bottom: 10px;
}

#quest-tasks {
    font-weight: bold;
    margin-bottom: 10px;
}

#quest-task-list {
    padding-right: 12px;
    height: 196px;
    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type: y proximity;
}

.quest-task {
    line-height: 24px;
    position: relative;
    margin-left: 20px;
}

.quest-task.current {
    scroll-snap-align: end;
}

.quest-task:before {
    position: absolute;
    left: -20px;
}

.quest-task.current:before {
    content: "\00B7";
    font-weight: bold;
    padding-left: 4px;
}

.quest-task.completed:before {
    content: "\2714";
}

#quest-journal {
    margin: 0px;
    white-space: pre-wrap;
    padding-right: 12px;
    height: 218px;
    overflow: hidden;
    overflow-y: auto;
}

#quest-options, #help-actions {
    padding: 10px;
    position: relative;
    text-align: left;
}

#quest-options button + button {
    margin-left: 10px;
}

#quest-content {
    margin-top: 40px;
    text-align: center;
    position: relative;
    flex: 1 1 auto;
}

#quest-conversation {
    height: 100%;
    max-height: 272px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

#quest-content.pending #quest-conversation {
    max-height: 545px;
}

#quest-conversation > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 450px;
}

#quest-conversation-from {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
    color: #F5C542;
}

#quest-conversation-text {
    padding: 0px 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-line;
}

.transcript-text {
    padding: 10px;
}

.transcript-text-entry + .transcript-text-entry {
    margin-top: 10px;
}

.transcript-text-entry-from {
    font-weight: bold;
    color: #F5C542;
    white-space: nowrap;
}

.transcript-text-entry-text {
    padding: 0px 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-line;
    text-align: left;
}

#quest-conversation-choices {
    margin-top: 40px;
    opacity: 0;
    transition: all 0.5s;
    pointer-events: none;
    counter-reset: choices 0;
    display: inline-block;
}

#quest-conversation > div#quest-conversation-choices {
    max-width: 960px;
    overflow-y: auto;
    flex-grow: 1;
}

#quest-conversation-choices.active {
    opacity: 1;
    pointer-events: all;
}

.quest-conversation-choice {
    cursor: pointer;
    line-height: 30px;
    counter-increment: choices 1;
    text-align: left;
    padding: 0px 10px;
    user-select: none;
}

.quest-conversation-choice::before {
    content: counter(choices) ') ';
    margin-right: 4px;
}

.quest-conversation-choice:hover {
    background: var(--hover);
}

.quest-conversation-choice.active, .quest-conversation-choice.faded {
    cursor: initial;
    background: none;
}

.quest-conversation-choice.faded {
    opacity: 0.25;
}

.quest-conversation-alignment {
    width: 30px;
    height: 30px;
    margin-left: -4px;
    display: inline-block;
}

.quest-conversation-alignment img {
    width: 100%;
    height: 100%;
}

.quest-conversation-alignment-display {
    width: 40px;
    opacity: 0;
    transition: all 1s;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.quest-conversation-alignment-display.active {
    opacity: 1;
}

.quest-conversation-alignment-display img {
    width: 100%;
}

#quest-conversation-reactions {
    transition: all 1s;
    position: absolute;
    left: 20px;
    bottom: 20px;
    max-height: 160px;
}

.quest-conversation-reaction {
    font-style: italic;
    max-height: 0px;
    opacity: 0;
    line-height: 20px;
    animation: appear 5s ease-in-out;
    overflow: hidden;
    text-align: left;
}

.button {
    border: 1px solid #15415b;
    background: none;
    font-size: 14px;
    padding: 5px 10px;
    line-height: 20px;
    text-transform: uppercase;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 1px #226a93;
    color: #98dff8;
    text-shadow: 0px 0px white;
    position: relative;
    min-width: 125px;
    max-width: 375px;
    letter-spacing: 1px;
    user-select: none;
}

.button:disabled {
    mix-blend-mode: luminosity;
    cursor: not-allowed;
}

.button:not([disabled]):hover::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0px 0px 32px 20px #DDD;
    filter: blur(1px);
    mix-blend-mode: color-dodge;
    width: 30px;
}

.glow-left::before, .glow-right::after {
    display: block;
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0px;
}

.glow-left::before {
    left: -4px;
    background: linear-gradient(to left, #005070, rgba(0, 0, 0, 0));
}

.glow-right::after {
    right: -4px;
    background: linear-gradient(to right, #005070, rgba(0, 0, 0, 0));
}

.glow-top::before, .glow-bottom::after {
    display: block;
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    left: 0px;
}

.glow-top::before {
    top: -4px;
    background: linear-gradient(to top, #005070, rgba(0, 0, 0, 0));
}

.glow-bottom::after {
    bottom: -4px;
    background: linear-gradient(to bottom, #005070, rgba(0, 0, 0, 0));
}

#options {
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    user-select: none;
}

.option:hover {
    box-shadow: inset 0 0 16px #78C7F1;
    cursor: pointer;
}

.option {
    width: 40px;
    height: 40px;
    box-shadow: inset 0 0 16px #14638d;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
}

#preferences button {
    margin-top: 10px;
}

#preferences-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    margin: auto;
}

.preference {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: left;
}

#preferences-group div ~ div {
    margin-top: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-text {
    margin: 0px;
    margin-left: 20px;
    cursor: pointer;
    user-select: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    transition: .5s;
    border: 1px solid white;
    border-radius: 10px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    top: 2px;
    background-color: #aaa;
    transition: .5s;
    border: 1px solid #aaa;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #375a7f;
    border: 1px solid #375a7f;
}

input:checked + .slider:before {
    transform: translateX(20px);
    background-color: white;
    border: 1px solid white;
}

#preferences input[type="range"] {
    display: inline-block;
    width: 180px;
    vertical-align: middle;
    height: 4px;
    user-select: initial;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
   background-color: #555;
   border-radius: 5px;
   height: 4px;  
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
   appearance: none;
   margin-top: -10px;
   background-color: #fff;
   height: 20px;
   width: 20px;
   border-radius: 50%;
   transition: 0.5s all;
}

input[type="range"]:focus::-webkit-slider-thumb {
    background-color: #fff;
}

input[type="range"]::-moz-range-track {
   background-color: #375a7f;
}

#preferences #active_event {
    width: auto;
}

.toggled {
    margin-top: 10px;
    margin-left: 20px;
}

.toggled .preference {
    justify-content: unset;
}

.conversation-choice-divider {
    margin: 20px auto;
}

#companions, #quests, #codices, #mail {
    display: flex;
    height: calc(100% - 20px);
}

#companions, #mail, #transcripts {
    display: flex;
    height: calc(100% - 10px);
}

#companion-list, #quest-list, #codex-list, #mail-list, #transcript-list {
    width: 400px;
    padding: 0px 10px;
    text-align: left;
    position: relative;
    flex: 1 1 auto;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 10px 0px;
    margin-bottom: 0px;
    max-width: 400px;
}

#codex-list {
    max-height: calc(100% - 40px);
}

.companion-header, .quest-history-header, .codex-header {
    padding-left: 10px;
    border: 1px solid rgb(30, 101, 147);
    border-radius: 5px;
    line-height: 24px;
    font-size: 14px;
    background: #00213d;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.companion-link {
    font-size: 14px;
    position: absolute;
    top: 2px;
}

.companion-toggle, .quest-history-toggle, .codex-toggle {
    font-size: 18px;
    font-weight: bold;
    position: absolute;
}

.companion-header.sub .companion-toggle {
    left: 32px;
}

.companion-category + .companion-category, .quest-history-category + .quest-history-category, .codex-category + .codex-category {
    margin-top: 5px;
}

.companion-category-group + .companion-header, .quest-history-category-group + .quest-history-header, .codex-category-group + .codex-header {
    margin-top: 5px;
}

.companion-header-text, .quest-history-header-text, .codex-header-text {
    margin-left: 20px;
}

.companion-header.sub .companion-header-text {
    margin-left: 40px;
}

.companion-category-group.collapsed, .quest-history-category-group.collapsed, .codex-category-group.collapsed  {
    display: none;
}

.companion-row, .help-row {
    height: 50px;
    border: 1px solid #002c51;
    border-radius: 5px;
    vertical-align: top;
    cursor: pointer;
    transition: all 0.5s;
}

.companion-row + .companion-row, .help-row + .help-row {
    margin-top: 5px;
}

.quest-history-row, .codex-row, .mail-row, .transcript-row {
    border: 1px solid transparent;
}

#quest-list-transcript {
    margin-top: 5px;
}

#mail-details {
    position: relative;
    text-align: left;
    height: 55px;
}

.mail-label {
    display: inline-block;
    width: 80px;
    margin-left: 20px;
    font-weight: bold;
}

.mail-subtext {
    display: inline-block;
    font-weight: bold;
}

.companion-row:hover, .quest-history-row:hover, .codex-row:hover, .help-row:hover, .mail-row:hover {
    border-color: #396d80;
    box-shadow: 0px 0px 10px 1px #356980;
}

.companion-row.active, .quest-history-row.active, .codex-row.active, .help-row.active, .mail-row.active {
    border-color: #71D9FF;
    box-shadow: 0px 0px 10px 1px #6ad1ff;
}

.companion-row-left {
    display: inline-block;
    vertical-align: top;
}

.companion-row-middle {
    margin-left: 10px;
    display: inline-block;
    width: calc(100% - 106px);
    vertical-align: middle;
}

.companion-row-right {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.companion-portrait img {
    margin-top: 2px;
    margin-left: 2px;
    width: 44px;
    height: 44px;
    border: 1px solid #002c51;
    border-radius: 5px;
}

.companion-row.summoned .companion-portrait img {
    border-color: #FFBC55;
    box-shadow: 0px 0px 10px 1px #F5C542;
}

.companion-name {
    height: 25px;
    line-height: 25px;
    font-weight: bold;
}

.companion-row-middle.is-alert {
    width: calc(100% - 56px);
}

.companion-row.summoned .companion-name, .mail-row.active .mail-row-from, .message-user {
    color: #FFBC55;
}

.companion-title {
    height: 21px;
    line-height: 21px;
    font-size: 12px;
}

.companion-summon {
    appearance: none;
    border: none;
    background: transparent;
    padding: 0px;
    display: block;
}

.companion-summon img {
    height: 24px;
}

#companion-view {
    width: auto;
    padding: 10px;
    width: 580px;
}

.companion-portrait-large {
    margin-right: 10px;
}

.companion-portrait-large img {
    border: 1px solid #224d60;
    border-radius: 5px;
    width: 100px;
    height: 100px;
}

.companion-view-details {
    display: flex;
    text-align: left;
}

.companion-name-large {
    font-size: 20px;
    font-weight: bold;
}

.companion-type {
    font-size: 14px;
    margin-top: 10px;
}

.companion-container-heading {
    text-align: left;
    position: absolute;
    top: 4px;
    left: 10px;
    font-size: 14px;
}

.companion-content {
    position: absolute;
    top: 30px;
    padding: 10px;
    text-align: left;
    font-size: 14px;
    width: calc(100% - 5px);
    height: calc(100% - 35px);
    overflow-y: auto;
}

.companion-view-text {
    position: relative;
    height: 100%;
    margin-top: 10px;
}

.companion-view-text.companion-alert {
    height: 450px;
    margin-bottom: 20px;
    margin-bottom: 10px;
}

.companion-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}

.companion-container path {
    fill: transparent;
    stroke: #224d60;
    stroke-width: 1.5px;
}

.companion-text {
    white-space: pre-wrap;
    width: 100%;
}

.companion-interaction {
    position: relative;
    margin-left: 20px;
}

.companion-interaction + .companion-interaction {
    margin-top: 10px;
}

.companion-interaction:before {
    content: "\2714";
    position: absolute;
    left: -20px;
    color: #317A3C;
    font-weight: bold;
}

.quest-history-row, .codex-row, .mail-row {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    user-select: none;
}

.transcript-row {
    border-radius: 5px;
    padding: 5px;
    user-select: none;
}

.transcript-row-conversation {
    font-size: 13px;
    margin-left: 5px;
    padding: 5px;
}

.transcript-row-conversation:hover {
    background: var(--hover);
    cursor: pointer;
}

.quest-history-row-category {
    font-size: 12px;
}

.mail-row-subject {
    font-size: 12px;
    color: #537d8f;
}

.mail-row.unread .mail-row-subject {
    text-shadow: 0 0 4px #b2eaff;
    color: #71d9ff;
}

#container select, #character-container input[type="text"], #character-container select, #character-container textarea, #user-container input, #container textarea {
    text-align: left;
    color: black;
    position: relative;
    -webkit-appearance: button;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    height: 32px;
    padding: 4px 9px;
    background: white;
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.4);
    font-weight: normal;
    overflow: hidden;
    text-shadow: 0 1px 0 white;
    -webkit-transition: box-shadow 200ms, border-color 200ms;
    -moz-transition: box-shadow 200ms, border-color 200ms;
    transition: box-shadow 200ms, border-color 200ms;
    white-space: nowrap; 
    outline: none;
    font-weight: bold;
    margin-left: 10px;
}

#character-container input[type="text"], #character-container textarea, #user-container input, #container textarea {
    text-shadow: unset;
    background: transparent;
    border-color: #15415b;
    box-shadow: 0px 0px 3px 1px #226a93;
    color: white;
}

#container select option, #character-container select option {
    text-shadow: none; 
    font-weight: bold;
}

#container select:focus, #character-container select:focus {
    outline: none;
    border-color: #66ccff;
    box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.4), 0 0 4px #66ccff; 
    box-shadow: 0px 0px 10px 1px #356980;
}

#character-container input[type="text"]:focus, #character-container textarea:focus, #user-container input:focus, #container textarea:focus {
    border-color: #71D9FF;
    box-shadow: 0px 0px 10px 1px #6ad1ff;
    outline: none;
}

#container select, #character-container select {
    border-color: #ececec;
    background-color: #ececec;
}

#container select, #character-container select {
    padding-right: 30px; 
}

#container select, #character-container select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAAzElEQVRIDWP8//8/Ay0BE8zws2fPgmyium1wC2AWUZsetYBgiI4GEcEgYiGoAqqgq6trGZAZia4emFGXlZeXR6OLw/hEx8G/f/+ygYY9hWkE0UD+IyCVhSyGzibagoqKiveMjIyJIHNBhgAN/wekYoGu/wji4wJEWwAyoKysbDfQ4ClQw7qAhh/CZTBMnOg4gGl4+fJluYSEhNC9e/fqYGJ4aaCLQN5lOHPmDJACAzAfyKIKTVIQ4XUpDslRC3AEDEJ46AcRIyg50hIAANnugKRDswA6AAAAAElFTkSuQmCC"), -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #ededed));
    background-repeat: no-repeat, repeat;
    background-position: 100% 50%, 0 0; 
}

#quest-history-left, #codex-list, #mail-left, #transcripts-left {
    display: flex;
    flex-direction: column;
}

#quest-history-right, #mail-right {
    flex: 1 1 auto;
    overflow-y: auto;
    margin-right: 5px;
}

#mail-right {
    margin-top: 10px;
    margin-left: 5px;
}

#quest-history-right #quest-summary-name {
    margin-top: 20px;
}

.quest-history-tasks {
    width: 350px;
    margin: auto;
    text-align: left;
}

.quest-history-count {
    padding-left: 10px;
    font-size: 13px;
    margin-top: 10px;
    line-height: 20px;
}

#codex-right, #transcripts-right {
    width: 580px;
    padding: 0px 5px;
}

#codex-heading, #transcript-heading {
    margin-top: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

#codex-text, #mail-text, .transcript-text {
    white-space: pre-line;
    text-align: justify;
    padding: 20px;
    max-height: calc(100% - 40px);
    overflow-y: auto;
    padding-bottom: 0px;
}

#codex-text, .transcript-text {
    padding-top: 0px;
    margin-top: 20px;
    max-height: calc(100% - 60px);
}

#mail-text {
    max-height: calc(100% - 65px);
    padding-top: 10px;
    margin-top: 10px;
}

#container input[type="text"] {
    border-radius: 5px;
    padding: 5px 10px;
    width: 390px;
    margin-left: 10px;
    font-weight: bold;
}

#container input[type="text"]:focus, #container input[type="text"]:focus-within {
    box-shadow: 0px 0px 10px 2px #71D9FF;
    outline: none;
}

.character {
    width: 300px;
    display: flex;
    text-align: left;
    border: 1px solid rgb(30, 59, 89);
    border-radius: 5px;
    vertical-align: top;
    cursor: pointer;
    padding: 5px;
    height: 72px;
}

.character.free {
    border: 1px solid transparent;
}

.character:not(.free):hover {
    border-color: #396d80;
    box-shadow: 0px 0px 10px 1px #356980;
}

#character-select .character.active {
    border-color: #71D9FF;
    box-shadow: 0px 0px 10px 1px #6ad1ff;
}

.character + .character {
    margin-top: 10px;
}

.character-portrait {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-top: 6px;
}

.character-portrait.create {
    margin-top: 5px;
}

.character-portrait img {
    width: 100%;
    height: 100%;
}

.character-name {
    font-weight: bold;
    line-height: 20px;
}

.character-type {
    line-height: 20px;
    font-size: 13px;
}

.character-quest {
    line-height: 20px;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 170px;
}

.character-pagination {
    border: 1px solid rgb(30, 59, 89);
    display: flex;
    padding: 5px;
    border-radius: 5px;
    align-items: center;
}

.character-pagination-prev, .character-pagination-next {
    height: 30px;
    width: 30px;
}

.character-pagination-current {
    width: 228px;
}

.character-pagination-button {
    border: 1px solid rgb(30, 59, 89);
    color: white;
    line-height: 30px;
    font-size: 12px;
    user-select: none;
    border-radius: 5px;
}

.character-pagination-button:not(.disabled):hover {
    border-color: #396d80;
    box-shadow: 0px 0px 10px 1px #356980;
    cursor: pointer;
}

.character-details {
    width: 170px;
}

.character.active .character-action, .character:hover .character-action {
    display: block;
}

.character-action {
    border: 1px solid #15415b;
    background: black;
    font-size: 14px;
    padding: 5px 10px;
    line-height: 20px;
    text-transform: uppercase;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 1px #226a93;
    color: #98dff8;
    text-shadow: 0px 0px white;
    position: relative;
    width: 60px;
    letter-spacing: 1px;
    user-select: none;
    margin-top: 15px;
    height: 30px;
    display: none;
    cursor: pointer;
}

.character-action.disabled {
    cursor: not-allowed;
    filter: grayscale(1);
}

.character-create-row .character-action {
    display: block;
    height: auto;
    margin-top: 0px;
    margin-left: 10px;
    width: auto;
}

.character-action:not(.disabled):hover {
    border-color: #71D9FF;
    box-shadow: 0px 0px 10px 1px #6ad1ff;
}

.character-name-new {
    line-height: 60px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
}

#character-actions {
    border: 1px solid rgb(30, 59, 89);
    border-radius: 5px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 50px;
    width: 300px;
}

.character-row {
    display: flex;
}

.character-select-columns {
    display: flex;
    margin-bottom: 10px;
}

.character-column {
    height: 400px;
}

.character-column + .character-column {
    margin-left: 10px;
}

.character-row + .character-row {
    margin-top: 10px;
}

#background-select {
    width: 662px;
    height: 552px;
}

#background-select.is-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
}

#character-container {
    margin-top: 30px;
    margin-left: 27px;
    width: 610px;
}

.character-title {
    font-weight: bold;
    line-height: 40px;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 6px;
    height: 40px;
}

.character.active .character-name {
    color: #FFBC55;
}

.option.delete svg {
    width: 16px;
    height: 16px;
}

#character-select .option img {
    max-width: 24px;
}

.option.disabled, .character-pagination-button.disabled {
    filter: grayscale(1);
}

.option.disabled:hover {
    box-shadow: inset 0 0 16px #14638d;
    cursor: not-allowed;
}

.character-create-row {
    display: flex;
}

.character-create-row + .character-create-row {
    margin-top: 10px;
}

.character-create-option {
    border-radius: 5px;
    filter: brightness(0.5);
    transition: 0.5s all;
    cursor: pointer;
}

.character-create-option.locked {
    cursor: revert;
}

#character-create .character-create-option:not(.locked):hover, .character-create-option.active {
    filter: unset;
}

.character-create-option img {
    width: 50px;
    height: 50px;
}

.character-create-option + .character-create-option {
    margin-left: 10px;
}

.character-create-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.character-create-group-heading {
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    margin: 10px;
}

.character-create-allegiances {
    display: flex;
    align-items: center;
}

.character-create-allegiance {
    margin-left: 10px;
    margin-right: 10px;
    filter: brightness(0.5);
    transition: 0.5s all;
    cursor: pointer;
}

.character-create-allegiance.locked {
    cursor: revert;
}

.character-create-allegiance.faded, .character-create-option.faded {
    filter: brightness(0.25);
}

#character-create .character-create-allegiance:not(.locked):hover, .character-create-allegiance.active {
    filter: unset;
}

.character-create-allegiance, .character-create-allegiance img {
    width: 60px;
    height: 60px;
}

.character-create-row.confirmation .character-action {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.character-create-option svg {
    width: 40px;
    height: 40px;
}

#character-create {
    position: relative;
    height: 506px;
}

.character-create-description {
    border: .1px solid rgb(30, 59, 89);
    margin: 10px;
    padding: 10px;
}

#character-create select {
    margin-left: 0px;
}

.character-create-alignment {
    width: 300px;
    margin: 10px auto;
}

.character-create-subheading {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
    color: #F5C542;
}

#character-create.choices {
    display: flex;
    flex-direction: column;
}

.character-create-choice-container {
    height: 466px;
    overflow-y: scroll;
}

#character-create.choices .character-create-group {
    flex-grow: 1;
}

.character-create-choice {
    font-weight: bold;
}

.character-create-choice, .character-create-choice-options {
    text-align: left;
    padding: 5px 10px;
}

.character-create-selection {
    width: 100%;
}

.character-create-selection + .character-create-selection {
    margin-top: 10px;
}

.character-create-choice-option {
    display: flex;
    align-items: flex-start;
}

.character-create-choice-option input {
    margin: 0px;
}

.character-create-choice-option label {
    margin: 0px;
    margin-left: 10px;
    font-weight: normal;
}

#character-create-back {
    position: absolute;
    top: -16px;
    right: -4px;
    width: 20px;
    height: 20px;
    border: 1px solid #396d80;
    border-radius: 5px;
    cursor: pointer;
}

.character-create-selection .alignment-bar {
    width: 300px;
    margin: auto;
    margin-bottom: 10px;
}

.character-create-selection .alignment-bar-indicator {
    transition: none;
}

.character-create-selection .alignment-bar-title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 80%;
}

.character-create-selection input[type="range"] {
    height: 12px;
    position: absolute;
    appearance: none;
    outline: none;
    background: transparent;
    border: transparent;
    -webkit-appearance: none;
    width: 308px;
    margin-left: -4px;
    z-index: 2;
}

.character-create-selection input[type="range"]::-webkit-slider-runnable-track {
    background: transparent;
    height: 12px;
}

.character-create-selection input[type="range"]::-moz-range-track {
    background: transparent;
    height: 12px;
}

.character-create-selection input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
   appearance: none;
   margin-top: -6px;
   border-radius: 0;
   background-color: white;
   height: 23px;
   width: 8px;
   opacity: 0;
}

.character-create-selection input[type="range"]::-moz-range-thumb {
    border: none;
    border-radius: 0;
    background-color: transparent;
    height: 23px;
    width: 8px; 
    opacity: 0;
}

#character-large-back {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    border: 1px solid #396d80;
    border-radius: 5px;
    cursor: pointer;
}

#character-large-back svg {
    width: 22px;
    height: 22px;
    margin-top: 2px;
}

#user-create-back {
    position: absolute;
    top: -56px;
    right: -10px;
    width: 20px;
    height: 20px;
    border: 1px solid #396d80;
    border-radius: 5px;
    cursor: pointer;
}

#character-create-back.is-modal {
    color: rgb(159, 219, 239);
    text-shadow: 0 0 rgb(123, 191, 231);
    font-family: monospace;
    line-height: 16px;
    padding-top: 2px;
}

#character-create-back svg, #user-create-back svg {
    width: 16px;
    height: 16px;
    margin-top: 2px;
}

#character-modal-small {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: black;
    width: 350px;
    border-radius: 5px;
    border-color: #396d80;
    box-shadow: 0px 0px 10px 1px #6ad1ff;
    border: 1px solid #71D9FF;
    padding: 10px;
    font-weight: bold;
    z-index: 4;
}

.character-modal-small-header + .character-modal-small-header {
    margin-top: 10px;
}

#character-modal-small input[type="text"] {
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
}

.character-modal-small-actions {
    display: flex;
}

.character-modal-small-action {
    width: 164px;
}

#character-modal-small .character-action {
    display: block;
    width: 80px;
}

#character-modal-small p {
    white-space: pre-line;
}

#character-modal-small.error .character-action {
    width: 150px;
}

#character-modal-small.alert .character-modal-small-action {
    width: 100%;
}

#character-modal-small.alert .character-action {
    margin: auto;
}

.character-action.right {
    margin-left: auto;
}

#modal-outer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.75;
    z-index: 3;
}

#help, #about {
    position: relative;
}

#help p {
    text-align: left;
    padding-left: 5px;
    margin-bottom: 0px;
    line-height: 20px;
}

#help-form {
    text-align: left;
    padding: 5px;
    height: 375px;
}

#help-form.reduced {
    height: 200px;
}

#help textarea {
    width: 600px;
    height: 250px;
    margin: 0px;
    resize: none;
}

#help textarea.update {
    height: 150px;
    white-space: normal;
}

#help-list {
    padding-left: 5px;
    padding-right: 5px;
    height: 411px;
    overflow-y: auto;
    margin-bottom: 4px;
}

#help-list.full {
    height: 415px;
    margin-bottom: 0px;
    padding: 5px 3px;
}

#help-list.reduced {
    height: 215px;
    margin-bottom: 0px;
    padding: 5px 3px;
}

#help-form select, #help-form textarea {
    margin-left: 0px;
}

.help-row {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 590px;
    height: 47px;
}

.help-label {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
}

.help-category {
    width: 125px;
    text-align: left;
    font-weight: bold;
}

.help-body {
    width: 318px;
    text-align: left;
    display: flex;
    align-items: center;
}

.help-body img {
    width: 24px;
    margin-right: 5px;
}

.help-body .help-content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.help-date {
    width: 125px;
    text-align: right;
}

#help-actions div.character-action {
    display: block;
    text-align: center;
    width: 140px;
    margin: 0px;
    margin-top: 5px;
}

#help-actions div.character-action + div.character-action {
    margin-left: auto;
}

#help-actions {
    padding: 5px;
    display: flex;
    height: 50px;
}

.character-title.no-margin {
    margin: 0px;
}

#help article {
    border: 1px solid #15415b;
    box-shadow: 0px 0px 3px 1px #226a93;
    border-radius: 5px;
    padding: 10px;
    white-space: pre-wrap;
}

#help article + article {
    margin-top: 10px;
}

.message-user {
    text-align: left;
    font-weight: bold;
    margin-bottom: 5px;
}

.message-body {
    text-align: left;
    word-wrap: break-word;
}

#preference-menu {
    position: absolute;
    top: 57px;
    background: black;
    z-index: 2;
    right: 18px;
    padding: 5px;
    border: 1px solid #15415b;
    box-shadow: 0px 0px 3px 1px #226a93;
    border-radius: 5px;
    user-select: none;
}

.sub-option {
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    padding: 0px 15px 0px 5px;
    cursor: pointer;
    width: 140px;
}

.sub-option:hover {
    background: var(--hover);
}

.sub-option img, .sub-option svg {
    max-width: 20px;
    max-height: 20px;
}

.sub-option-icon {
    display: flex;
    width: 20px;
    margin-right: 10px;
    justify-content: center;
}

.sub-option-text {
    font-size: 14px;
}

#character-details {
    position: relative;
}

#character-titles {
    position: absolute;
    top: 25px;
    background: black;
    z-index: 2;
    left: 0px;
    padding: 5px;
    border: 1px solid #15415b;
    box-shadow: 0px 0px 3px 1px #226a93;
    border-radius: 5px;
    user-select: none;
    min-width: 250px;
}

.character-title-header {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.character-title-list {
    max-height: 200px;
    overflow-y: auto;
    white-space: nowrap;
    font-size: 14px;
    line-height: 25px;
    padding: 0px 5px;
}

.character-title-list-item {
    cursor: pointer;
    padding: 0px 10px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.character-title-list-item:hover {
    background-color: var(--hover);
}

#about-body {
    padding-left: 5px;
    padding-right: 10px;
    text-align: left;
    height: 460px;
    overflow-y: scroll;
}

#about-body h4 {
    font-size: 16px;
    text-align: center;
    margin: 15px;
    margin-top: 30px;
    text-decoration: underline;
    font-weight: bold;
}

#about-body h5 {
    font-size: 15px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

#about-body p ~ h5 {
    margin-top: 30px;
}

.user-title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 32px;
}

#user-container .user-field.actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.user-field label {
    margin-bottom: 0px;
    width: 100px;
    text-align: left;
    display: inline-block;
}

.user-field button + button {
    margin-left: 42px;
}

.user-field + .user-field {
    margin-top: 10px;
}

#background-user {
    width: 370px;
    height: 358px;
}

#user-container {
    margin-top: 72px;
    width: 302px;
    height: 230px;
    margin-left: 36px;
    position: relative;
}

#user-container.reconnect .user-title, #user-container.reconnect .loading {
    margin-bottom: 5px;
}

#user-container.reconnect button {
    margin-top: 10px;
}

.loading, .loading svg, svg.codex {
    width: 128px;
    height: 128px;
    margin: auto;
    margin-bottom: 20px;
}

/* Mobile specific */

#background-select.narrow {
    width: 100%;
    height: auto;
    margin: auto;
    min-height: 100vh;
    min-height: 100dvh;
    padding: 5px;
}

#background-select.narrow #character-container {
    width: 100%;
    height: 100vh;
    height: 100dvh;
    margin: auto;
}

#background-select.narrow .character-select-columns {
    flex-direction: column;
}

#background-select.narrow .character-column {
    height: auto;
}

#background-select.narrow .character-column + .character-column {
    margin-left: 0px;
    margin-top: 10px;
}

#background-select.narrow .character {
    width: 100%;
}

#background-select.narrow .character-row {
    flex-direction: column;
}

#background-select.narrow .character-details {
    width: auto;
    flex: 1 1 auto;
}

#background-select.narrow .character-pagination {
    height: 50px;
}

#background-select.narrow .character-pagination-current {
    width: auto;
    flex: 1 1 auto;
}

#background-select.narrow #character-actions {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
}

#background-select.narrow #character-create {
    height: 100%;
}

#background-select.narrow #character-create-back {
    top: 5px;
    right: 0px;
}

#background-select.narrow #about-body {
    height: calc(100vh - 40px);
    height: calc(100dvh - 40px);
    overflow-y: auto;
    padding-right: 5px;
}

#background-select.narrow #help-list {
    flex: 1 1 auto;
    padding: 0px;
}

#background.narrow #help-list {
    flex: 1 1 auto;
    padding: 0px;
}

#background-select.narrow textarea, #background.narrow textarea {
    width: 100%;
}

#background-select.narrow select, #background.narrow select {
    width: auto;
    margin-right: 10px;
    max-width: 100%;
}

#background-select.narrow .help-row, #background.narrow .help-row {
    width: 100%;
    display: grid;
}

#background-select.narrow .help-row .help-category, #background.narrow .help-row .help-category {
    grid-area: 1 / 1 / 2 / 2;
    width: auto;
}

#background-select.narrow .help-row .help-body, #background.narrow .help-row .help-body {
    grid-area: 2 / 1 / 3 / 3;
    width: calc(100vw - 25px);
}

#background-select.narrow .help-row .help-date, #background.narrow .help-row .help-date {
    grid-area: 1 / 2 / 2 / 3;
    width: auto;
}

#background.narrow {
    width: 100vw;
    height: 100vh;
    height: 100dvh;
}

#background.narrow #container {
    width: 100%;
    margin: auto;
    height: auto;
    background: none;
}

#background.narrow #main {
    width: 100%;
    margin: auto;
    height: auto;
}

#background.narrow #main-content {
    height: calc(100vh - 94px);
    height: calc(100dvh - 94px);
}

#background.narrow #character {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}

#background.narrow #character-levels {
    margin-top: 10px;
}

#background.narrow #xp-bar {
    width: calc(100vw - 20px);
}

#background.narrow #character-titles .alignment-bar {
    width: auto;
    margin: 10px;
}

#background.narrow #blurb, #background.narrow #preferences {
    width: 100%;
    padding: 10px;
    height: 100%;
    overflow-y: auto;
}

#background.narrow #side {
    position: absolute;
    left: calc(100vw + 4px);
    top: 94px;
    background: black;
    transition: 0.5s all;
    height: calc(100vh - 95px);
    height: calc(100dvh - 95px);
    background: black;
}

#background.narrow #side.active {
    left: calc(100vw - 300px);
}

#background.narrow #companion-list, #background.narrow #quest-list, #background.narrow #codex-list, #background.narrow #mail-list {
    width: 100%;
    overflow-y: auto;
}

#background.narrow #companion-view {
    position: absolute;
    left: 100vw;
    transition: 0.5s all;
    width: 100%;
    background: black;
}

#background.narrow #companion-view.active {
    left: 0px;
}

#background.narrow .companion-container {
    width: 100%;
    height: 100%;
}

#background.narrow .companion-content {
    height: calc(100% - 35px);
    width: calc(100% - 5px);
}

#background.narrow .companion-text {
    width: 100%;
}

#background.narrow #quests select {
    width: calc(100% - 20px);
}

#background.narrow #quest-history-left, #background.narrow #mail-left, #background-narrow #transcripts-left {
    width: 100%;
}

#background.narrow #quest-history-right, #background.narrow #mail-right {
    position: absolute;
    left: 100vw;
    transition: 0.5s all;
    width: 100%;
    max-height: unset;
    height: calc(100vh - 105px);
    height: calc(100dvh - 105px);
    background: black;
}

#background.narrow #quest-history-right.active, #background.narrow #mail-right.active {
    left: 0px;
}

#background.narrow #mail-right.active {
    margin-left: 0px;
}

#background.narrow #quest-history-right #quest-summary {
    width: 100%;
}

#background.narrow #codex-left input {
    width: calc(100% - 20px);
    margin-right: 10px;
}

#background.narrow #codex-right, #background.narrow #transcripts-right {
    position: absolute;
    left: 100vw;
    transition: 0.5s all;
    width: 100%;
    height: calc(100vh - 104px);
    height: calc(100dvh - 104px);
    overflow: auto;
    background: black;
    padding-bottom: 10px;
}

#background.narrow #codex-right #codex-text, #background.narrow #mail-right #mail-text, #background.narrow #transcripts-right .transcript-text {
    max-height: unset;
}

#background.narrow #mail-right #mail-text {
    height: calc(100% - 70px);
}

#background.narrow #codex-right.active, #background.narrow #transcripts-right.active {
    left: 0px;
}

#background-select.narrow #character-select {
    height: calc(100% - 51px);
}

#background.narrow #quest-conversation #quest-conversation-begin {
    margin-left: unset;
    margin-right: unset;
}

#background.narrow #codex-right::-webkit-scrollbar, #background.narrow #codex-list::-webkit-scrollbar, #background-select.narrow #about-body::-webkit-scrollbar, #background.narrow #main-content #about-body::-webkit-scrollbar, #background-select.narrow #help-list::-webkit-scrollbar, #background.narrow #help-list::-webkit-scrollbar, #background.narrow #companion-list::-webkit-scrollbar, #background.narrow #blurb::-webkit-scrollbar, #background.narrow #preferences::-webkit-scrollbar, #background.narrow #quest-conversation-choices::-webkit-scrollbar, #background.narrow #quest-list::-webkit-scrollbar, #background.narrow .companion-content::-webkit-scrollbar, #background.narrow #quest-history-right::-webkit-scrollbar, #background.narrow #mail-right #mail-text::-webkit-scrollbar, #background-select.narrow .character-create-choice-container::-webkit-scrollbar, #background.narrow #transcripts-right::-webkit-scrollbar {
    display: none;
}

#background.narrow #codex-right, #background.narrow #codex-list, #background-select.narrow #about-body, #background.narrow #main-content #about-body, #background-select.narrow #help-list, #background.narrow #help-list, #background.narrow #companion-list, #background.narrow #blurb, #background.narrow #preferences, #background.narrow #quest-conversation-choices, #background.narrow #quest-list, #background.narrow .companion-content, #background.narrow #quest-history-right, #background.narrow #mail-right #mail-text, #background-select.narrow .character-create-choice-container, #background.narrow #transcripts-right {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#background.narrow #codex-left, #background.narrow #transcripts-left {
    width: 100%;
}

#background.narrow #transcripts-left .transcript-row-conversation:hover {
    background: none;
}

#background.narrow #quest {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#background.narrow #quest-info {
    height: auto;
    flex-direction: column;
}

#background.narrow #quest-info-left, #background.narrow #quest-info-right {
    width: 100%;
    max-height: calc((100vh - 175px) / 2);
    max-height: calc((100dvh - 175px) / 2);
}

#background.narrow #quest-journal {
    height: auto;
    max-height: calc((100vh - 175px) / 2 - 54px);
    max-height: calc((100dvh - 175px) / 2 - 54px);
    transition: 0.5s max-height;
}

#background.narrow #quest-history-right #quest-summary-name {
    padding: 0px 5px;
}

#background.narrow #quest-task-list {
    height: auto;
    max-height: 100%;
    transition: 0.5s max-height;
}

#background.narrow #quest-content {
    height: auto;
    margin-bottom: 5px;
    flex: 1 1 auto;
}

#background.narrow #quest-options {
    display: none;
}

#background.narrow #quest.conversation #quest-heading {
    margin-bottom: 0px;
}

#background.narrow #quest.conversation #quest-content {
    margin-top: 20px;
    overflow-y: auto;
}

#background.narrow #quest-conversation {
    max-height: unset;
}

#background.narrow #companion-list, #background.narrow #quest-list, #background.narrow #codex-list, #background.narrow #mail-list {
    max-width: unset;
}

#background.narrow #quest.conversation #quest-journal, #background.narrow #quest.conversation .quest-task.completed {
    max-height: 0px;
    margin: 0px;
    padding: 0px;
    opacity: 0;
}

#background-select.narrow #help, #background-select.narrow #about {
    min-height: 100vh;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
}

#background.narrow #main-content #about, #background.narrow #main-content #help {
    height: 100%;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
}

#background.narrow #main-content #about-body {
    height: calc(100vh - 139px);
    height: calc(100dvh - 139px);
    overflow-y: auto;
    padding-right: 5px;
}

#background.narrow .button {
    max-width: calc(100% - 20px);
}

#background.narrow #quest-summary {
    width: 100%;
}

.character-narrow-alignment {
    margin-top: 20px;
}

#background-user.narrow  {
    width: auto;
    height: auto;
    border-radius: 5px;
    border: 1px solid #002c51;
    padding: 10px;
    box-shadow: 0px 0px 10px 1px #356980;
}

#background-user.narrow #user-container {
    margin: auto;
}

#background-user-outer {
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

#toggle-menu {
    width: 20px;
    height: 2px;
    border-radius: 2px;
    background: white;
    position: absolute;
    top: 16px;
    right: 10px;
    cursor: pointer;
}

#toggle-menu.hint, #toggle-menu.hint::before, #toggle-menu.hint::after {
    animation: alert 2s infinite;
}

#toggle-menu::before {
    content: "";
    width: 20px;
    height: 2px;
    display: block;
    border-radius: 2px;
    background: white;
    margin-top: -6px;
}

#toggle-menu::after {
    content: "";
    width: 20px;
    height: 2px;
    display: block;
    border-radius: 2px;
    background: white;
    margin-top: 10px;
}

#main-content.faded {
    opacity: 0.25;
}

#user-container input {
    width: 192px;
}

#background #preferences select {
    margin-left: 0px;
}

#background #character-name {
    max-width: calc(100vw - 45px);
}

#background #character-titles {
    max-width: calc(100vw - 20px);
}

#background-select.narrow .character-create-choice-container {
    height: calc(100% - 50px);
    overflow-y: scroll;
}

/* Wide specific */

#background.wide {
    width: auto;
    height: auto;
}

#background.wide #container {
    margin: auto;
    height: 100vh;
    height: 100dvh;
}

@keyframes appear {
    0% {
        max-height: 0px;
        opacity: 0;
    }
    20% {
        max-height: 100px;
        opacity: 1;
    }
    80% {
        max-height: 100px;
        opacity: 1;
    }
    100% {
        max-height: 0px;
        opacity: 0;
    }
}

@keyframes alert {
    0% { 
        background-color: white;
    }
    50% { 
        background-color: #4fb1d6;
    }
    100% { 
        background-color: white;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 12px;
    background: #030405;
    border: 1px solid #19415a;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    height: 12px;
    background: #4fb1d6;
    border: 2px solid #a4eeff;
    border-radius: 3px;
}

body {
    scrollbar-width: 10px;
}